export default {
  common: {
    dragAndDrop: "Haz click aquí o arrastra los archivos",
    aceptar: "Aceptar",
    cancelar: "Cancelar",
    ok: "Ok",
    tipo: "Tipo",
    nombre: "Nombre",
    recargar: "Recargar",
    editar: "Editar",
    volver: "Volver",
    guardar: "Guardar",
    subir: "Subir",
    validar: "Validar campos",
    eliminar: "Eliminar",
    cups: "CUPS",
    exportar: "Exportar",
    cargados: "Cargados {n} de {t}",
    cargar_mas: "Cargar más",
    descargar: "Descargar",
    busqueda: "Búsqueda...",
    exportar: "Exportar",
    ver: "Ver",
  },

  audios_gana: {
    venta: "Audio Venta",
    legal: "Texto Legal",
    certificado: "Certificado",
    subir_todo: "Subir todo",
    nif_no_detectado: "No se ha dectado el NIF de los siguientes archivos:",
    subida_ok: "Los archivos se han subido correctamente",
  },

  confirm: {
    eliminar: {
      archivo: {
        title: "Eliminar archivo",
        text: "¿Estás seguro de que quieres eliminar este documento?",
      },
      borrador: {
        title: "Eliminar borrador",
        text: "¿Estás seguro de que quieres eliminar este borrador? Esta acción no se puede deshacer",
        ok_result: "Eliminado con éxito",
      },
      mensaje: {
        title: "Eliminar mensaje",
        text: "¿Estás seguro de que quieres eliminar este mensaje? Esta acción no se puede deshacer",
        ok_result: "Eliminado con éxito",
      },
    },
    revision: {
      title: "Enviar a revisión",
      text: "¿Estás seguro de que quieres enviar este borrador a revisión? Una vez enviado no podrás modificar los datos",
      ok_result: "Guardado como contrato | Guardado como borrador",
      error_result:
        "Error al guardar como contrato | Error al guardar como borrador",
    },
    anular: {
      title: "Anular contrato",
      text: "¿Estás seguro de que quieres anular este contrato?",
      ok_result: "Contrato anulado con éxito",
      error_result: "Error al anular el contrato",
      motivo: "Motivo anulación",
    }
  },

  login: {
    error: "Los datos de acceso no son correctos",
    usuario: "Usuario",
    pass: "Contraseña",
    login: "Acceder",
  },

  noticias: {
    novedades: "Novedades",
    mas_menos: "Leer más | Leer menos",
  },

  menu: {
    inicio: "Inicio",
    contratos: "Contratos",
    borradores: "Borradores",
    documentos: "Documentos",
    facturas: "Facturas",
    verificaciones: "Verificaciones",
    llamadas: "Llamadas",
    audios_gana: "Audios Gana",
    leads: "Leads",
    comparativas: "Comparativas",
    comparativas_qr: "Comparativas desde QR",
    chat: "Chat",
    tarjetas: {
      inicio: "@:menu.inicio",
      contratos:
        "Tienes {n} contratos detenidos | Tienes 1 contrato detenidos | No tienes contratos detenidos",
      borradores:
        "Tienes {n} borradores por enviar | Tienes 1 borrador por enviar | No tienes borradores por enviar",
      facturas:
        "Tienes {n} facturas pendientes | Tienes 1 factura pendiente | No tienes facturas pendientes",
      verificaciones:
        "Tienes {n} verificaciones por subir | Tienes 1 verificación por subir | No tienes verificaciones por subir",
      documentos: "Documentos disponibles para descargar",
      leads: "{0} registrados",
      comparativas: "Realiza comparativas de luz o gas a partir del CUPS",
      chat: "Tienes {0} mensajes sin leer",
    },
  },

  borradores: {
    nuevo: "Nuevo borrador",
    fecha: "Fecha",
    cups: "@:common.cups",
    comer: "Comercializadora",
    tipo_contrato: "Tipo contrato",
    llamadas: "Llamadas",
    tarifa: "Tarifa",
    potencia: "Pot {p}",
    consumo: "Consumo",
    oferta: "Oferta",
    dni_titular: "DNI/NIF titular",
    nombre_titular: "Nombre titular",
    dni_firmante: "DNI/NIF firmante",
    nombre_firmante: "Nombre firmante",
    cp_cups: "@:borradores.cp @:common.cups",
    pob_cups: "Población @:common.cups",
    dir_cups: "Dirección @:common.cups",
    prov_cups: "Provincia @:common.cups",
    telf: "Teléfono",
    email: "Email",
    iban: "Cuenta bancaria",
    otros: "Otros datos",
    cups2: "@:common.cups 2",
    refcomer: "REF.COMER",
    refcomer2: "REF.COMER2",
    cnae: "CNAE",
    cambiotit: "Camb.Tit",
    cambiopot: "Camb.Pot",
    fecha_act: "Fecha activación",
    dir_corres: "Dirección correspondencia",
    cp: "Cód.Postal",
    cp_corres: "Cód.Postal correspondencia",
    per_contacto: "Persona contacto",
    telf2: "@:borradores.telf 2",
    prod_adicionales: "Productos adicionales",
    documentacion: "Documentación",
    adjunta_doc: "Adjunta aquí la documentación del contrato",
    validacion_ok: "Campos validados con éxito",
    cups_o_titular:
      "Es obligatorio un CUPS o un titular para guardar el borrador",
    intervalo: "Intervalo",
    factura_electronica : "Factura electónica",
    pago_domiciliado: 'Pago domiciliado',
  },

  documentacion: {
    not_found: "No se ha encontrado el documento",
    error_subir: "No se ha podido subir el documento",
  },
  verificacion: {
    adjuntar: "Adjuntar verificación",
    not_found: "No se ha encontrado el documento",
    error_subir: "No se ha podido subir el documento",
  },

  contratos: {
    estado: "Estado contrato",
    motivo_dev: "MOTIVO DEVOLUCIÓN",
    motivo_anu: "MOTIVO ANULACIÓN",
    motivo_det: "MOTIVO DETENCIÓN",

    datos: "Datos contrato",
    factura: "Factura pago",
    fecha: "Fecha",
    bimp: "B.Imp.",

    ver_doc: "Ver documento",
    ver_fac: "Ver factura",
    anular: "Anular contrato",
    motivo_anulacion: "Motivo anulación",

    comentario: "Comentario",
    agente: "Agente",
    tipo: "Tipo",
    nombre: "Nombre",

    documentos: "Documentos adjuntos",
    historico: "Histórico contrato",
  },

  descargas: {
    vacio: "No hay archivos disponibles para descargar",
    comers: "TODAS COMERCIALIZADORAS",
  },

  facturas: {
    estado: "Estado factura",
    pago_fac: "Pago factura",
    cuenta_pago: "Cuenta pago",
    ver_doc: "Ver documento",
    desglose: "Desglose factura",
    datos: "Datos factura",
    razon_social: "Razón social",
    nif: "NIF/CIF",
    fecha_fac: "Fecha Factura",
    n_doc: "Número documento",
    bimp: "Base Imponible",
    iva: "IVA {pct}%",
    irpf: "IRPF {pct}%",
    total: "Total Factura",
    dir: "Dirección",
    pob: "Población",
    prov: "Provincia",
    cp: "Cod.Postal",
    contenido: "Contenido factura",
    lineas: "1 línea | {n} líneas",
    hist: "Histórico factura",
    ver_contrato: "Abrir el contrato",
    adjuntar: "Adjuntar documento",
    fechafactura: "Fecha factura",
    numerofactura: "Nº factura",
    bifactura: "Base Imp.",
    totalfactura: "Total factura",
    estadofactura: "Estado factura",
  },

  chat: {
    archivos_adjuntos: "1 archivo adjunto | {n} archivos adjuntos",
    escribe_aqui: "Escribe aquí tu mensaje...",
    adjuntar_archivos: "Adjuntar archivos",
  },
};
